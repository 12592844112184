import { useState } from "react"
import styled from "styled-components"

import { useDebouncedCallback } from "use-debounce"

import { spacing } from "src//ui/spacing"
import { FormInput } from "src/components/HoneyPot/FormInput"
import { MarketingConsentCheckbox } from "src/components/Signup/MarketingConsentCheckbox"
import { TosCheckbox } from "src/components/Signup/TosCheckbox"
import { usePostPasswordValidity } from "src/data/user/queries/userQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { DocumentHead } from "src/router/DocumentHead"
import { getInviteCodeFromUrl } from "src/router/organizationRoutes"
import { MButton } from "src/ui/Button/MButton"
import { divider } from "src/ui/colors"
import Logo from "src/ui/icons/logo.svg"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MCircularProgress } from "src/ui/MCircularProgress/MCircularProgress"
import { MText } from "src/ui/MText"
import { MTextField } from "src/ui/MTextField/MTextField"

export interface ICreateAccountSubmit {
  name: string
  email: string
  password: string
  subscribe_newsletter: boolean
  honey: string
}
export function CreateAccount({
  onSubmit: handleSubmit,
  creatingAccount,
  error,
  prefillEmail,
}: {
  onSubmit: (accountConf: ICreateAccountSubmit) => void
  creatingAccount: boolean
  error: React.ReactNode
  prefillEmail?: string
}) {
  const [email, setEmail] = useState(prefillEmail ?? "")
  const [name, setName] = useState("")
  const [password, setPassword] = useState("")
  const [tosAccepted, setTosAccepted] = useState(false)
  const [subscribeNewsletter, setSubscribeNewsletter] = useState(false)
  const [honey, setHoney] = useState("")

  const [passwordError, setPasswordError] = useState({
    isError: false,
    message: "",
  })

  const { t, langKeys } = useTranslate()

  const inviteCode = getInviteCodeFromUrl()
  const postPasswordValidity = usePostPasswordValidity()
  const debouncedPostPasswordValidity = useDebouncedCallback(
    (password: string) => {
      postPasswordValidity.mutate(
        { password },
        {
          onError: (error) => {
            if (error.response?.data.context?.warning) {
              setPasswordError({
                isError: true,
                message: error.response.data.context.warning,
              })
            } else {
              setPasswordError({
                isError: true,
                message: t(langKeys.password_too_weak),
              })
            }
          },
        }
      )
    },
    500
  )

  function handleSetPassword(password: string) {
    if (password.length < 8) {
      setPasswordError({
        isError: true,
        message: t(langKeys.password_too_short),
      })
    } else if (password.length > 50) {
      setPasswordError({
        isError: true,
        message: t(langKeys.password_too_long),
      })
    } else {
      setPasswordError({
        isError: false,
        message: "",
      })
      debouncedPostPasswordValidity(password)
    }
    setPassword(password)
  }

  return (
    <PageBox>
      <DocumentHead
        title={t(langKeys.sign_up_meta_title)}
        metaTags={[
          {
            name: "description",
            content: t(langKeys.sign_up_meta_description),
          },
        ]}
      />

      <LogoBox>
        <Logo width={90} />
      </LogoBox>

      {(!!inviteCode && (
        <MText variant="heading2" marginBottom={spacing.L}>
          Create an account to join this team on Minut
        </MText>
      )) || (
        <MText variant="heading1" marginBottom={spacing.L}>
          {t(langKeys.create_an_account)}
        </MText>
      )}

      <FormBox
        onSubmit={(e) => {
          e.preventDefault()

          handleSubmit({
            name,
            email,
            password,
            honey,
            subscribe_newsletter: subscribeNewsletter,
          })
        }}
      >
        <MTextField
          id="fullName"
          label={t(langKeys.name)}
          type="text"
          autoFocus
          required
          value={name}
          onChange={(value) => setName(value)}
          onBlur={(e) => setName(e.target.value.trim())}
        />
        <MTextField
          id="email"
          label={t(langKeys.email)}
          type="email"
          required
          value={email}
          onChange={(value) => setEmail(value)}
          onBlur={(e) => setEmail(e.target.value.toLocaleLowerCase().trim())}
          disabled={!!prefillEmail}
        />

        <MTextField
          id="password"
          label={t(langKeys.password)}
          type="password"
          required
          error={passwordError.isError ? passwordError.message : undefined}
          value={password}
          onChange={(value) => handleSetPassword(value)}
          endAdornment={
            postPasswordValidity.isLoading ||
            debouncedPostPasswordValidity.isPending() ? (
              <MCircularProgress color="inherit" size={20} />
            ) : (
              <></>
            )
          }
        />

        <MarketingConsentCheckbox
          checked={subscribeNewsletter}
          onChecked={(checked) => setSubscribeNewsletter(checked)}
        />

        <FormInput value={honey} onChange={setHoney} />

        <TosCheckbox
          checked={tosAccepted}
          onChecked={(checked) => setTosAccepted(checked)}
        />

        {!!error && (
          <ErrorBox type="error" fullWidth>
            {error}
          </ErrorBox>
        )}

        <StyledButton
          fullWidth
          type="submit"
          disabled={
            !tosAccepted ||
            creatingAccount ||
            !postPasswordValidity.isSuccess ||
            password.length < 8 ||
            password.length > 50
          }
          loading={creatingAccount}
        >
          {t(langKeys.create_an_account)}
        </StyledButton>
      </FormBox>

      {!prefillEmail && (
        <MText variant="bodyS" color="secondary">
          {t(langKeys.sign_up_redirect_to_sign_in)}{" "}
          <InternalLink to={`/login${window.location.search}`}>
            {t(langKeys.sign_in)}
          </InternalLink>
        </MText>
      )}
    </PageBox>
  )
}

const LogoBox = styled.div`
  text-align: center;
  margin-bottom: ${spacing.L};
`

const ErrorBox = styled(MBanner)`
  margin-top: ${spacing.M};
  margin-bottom: ${spacing.M};
`

const StyledButton = styled(MButton)`
  margin: ${spacing.M} 0;
`

const PageBox = styled.main`
  display: flex;
  max-width: 480px;
  min-height: calc(100vh - 120px);
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: ${spacing.XL} ${spacing.L};
  margin: auto;
`

const FormBox = styled.form`
  display: grid;
  gap: ${spacing.S};
  font-size: 0.875rem;
  padding-bottom: ${spacing.S};
  margin-bottom: ${spacing.M};
  border-bottom: 1px solid ${divider};
`
